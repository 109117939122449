<template>
  <div class="flex flex-col w-full h-full min-w-full">
    <div v-if="!simple" class="flex flex-row justify-between">
      <div class="my-auto text-sm font-semibold uppercase" v-text="t('general.filter')" />
      <div class="flex items-center">
        <tx-button type="text" class="text-primary-500" :text="t('general.reset')" @click="doReset" />
      </div>
    </div>
    <div class="flex-shrink mb-5 overflow-auto grow" style="min-width: 0;">
      <fieldset
        v-for="(itm, index) in criteria" :key="itm.attribute" class="relative p-4 mb-2 ml-2 border-dashed"
        :class="`${itm.attribute === selectedFilter?.attribute ? 'border border-blue-500' : 'border-b border-grey'}`"
      >
        <articles-filter-criteria v-model="criteria[index]" :focus="itm.attribute === selectedFilter?.attribute" :exclude="getExcludedAttributes" />
      </fieldset>
      <tx-button
        v-if="userStore.myAttributes && criteria.length < Object.keys(userStore.myAttributes).length" type="text" faicon="fa-light fa-plus"
        :text="t('filter.addCriteria')" width="150px" height="32px" @click="doAddCriteria"
      />
    </div>
    <div v-if="!simple" class="flex flex-row justify-between flex-shrink-0 mb-5 h-14 min-h-14">
      <tx-button type="cancel" :text="t('general.cancel')" @click="doCancel" />
      <tx-button type="confirm" :text="t('general.apply')" @click="doConfirm" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clone } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref } from 'vue'
import TxButton from '@/shared/components/TxButton.vue'
import ArticlesFilterCriteria from '@/shared/components/ArticlesFilterCriteria.vue'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'
import { useUserStore } from '@/store/userData'
import useFilterableAttributes from '@/shared/composables/filterableAttributes'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'

const props = defineProps<{
  criteria?: FilterCriteria[]
  selectedFilter?: FilterCriteria
  simple?: boolean
}>()

const emit = defineEmits<{ (e: 'cancel'): void, (e: 'confirm', criteria: FilterCriteria[]): void }>()

const { filterableAttributesByAttributeSystemName } = useFilterableAttributes()

const { t } = useI18n()
const userStore = useUserStore()
const criteria = ref<FilterCriteria[]>([])

const getExcludedAttributes = computed(() => {
  return criteria.value.map(itm => itm.attribute).filter(itm => itm && itm !== '')
})

function doReset() {
  criteria.value = []
  criteria.value.length = 0
  addDefaultCriteria()
  if (criteria.value.length === 0) {
    criteria.value = [new FilterCriteria()]
  }
}

// function doRemoveCriteria(index: number) {
//   criteria.value?.splice(index, 1)
// }

async function doAddCriteria() {
  criteria.value.push(new FilterCriteria())
}

function doCancel() {
  emit('cancel')
}

function doConfirm() {
  emit('confirm', getCriteriaResult())
}

function getCriteriaResult() {
  return criteria.value.filter(itm => itm.attribute && itm.attribute.length > 0 && itm.hasValue())
}

function addDefaultCriteria() {
  if (userStore.activeCatalog && userStore.activeCatalog.Config.DefaultFilterAttributes.length) {
    for (const defaultFilterAttribute of userStore.activeCatalog.Config.DefaultFilterAttributes) {
      if (utils.isDefined(filterableAttributesByAttributeSystemName.value[defaultFilterAttribute])) {
        let mode = FilterCriteriaMode.string
        if (defaultFilterAttribute === 'Status') {
          mode = FilterCriteriaMode.status
        }
        else if (defaultFilterAttribute === 'ArticleNumber' || defaultFilterAttribute === 'ModelNumber' || filterableAttributesByAttributeSystemName.value[defaultFilterAttribute].FilterLookup.size > 0) {
          mode = FilterCriteriaMode.multiString
        }
        else if (filterableAttributesByAttributeSystemName.value[defaultFilterAttribute].AttributeType === AttributeType.Int || filterableAttributesByAttributeSystemName.value[defaultFilterAttribute].AttributeType === AttributeType.Decimal) {
          mode = FilterCriteriaMode.numberRange
        }
        else if (filterableAttributesByAttributeSystemName.value[defaultFilterAttribute].AttributeType === AttributeType.Date) {
          mode = FilterCriteriaMode.dateRange
        }
        else if (filterableAttributesByAttributeSystemName.value[defaultFilterAttribute].AttributeType === AttributeType.DateTime) {
          mode = FilterCriteriaMode.dateTimeRange
        }
        criteria.value.push(new FilterCriteria({ attribute: defaultFilterAttribute, mode, exclude: false }))
      }
    }
  }
}

function resetCriteria() {
  doReset()
  if (props.criteria && props.criteria.length) {
    props.criteria.forEach((c) => {
      const existingIndex = criteria.value.findIndex(criteria => criteria.attribute === c.attribute)
      if (existingIndex === -1) {
        criteria.value.push(clone(c))
      }
      else {
        criteria.value[existingIndex] = clone(c)
      }
    })
  }
  if (criteria.value.length === 0) {
    doReset()
  }
}

onMounted(() => {
  resetCriteria()
})

defineExpose({
  getCriteriaResult,
  resetCriteria,
})
</script>
