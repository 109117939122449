import { AttributeType } from './catalogAttribute'

export const requestConstants = {
  requestStates: {
    new: 1,
    approve: 2,
    reject: 3,
    draft: 4,
    confirm: 5,
  },
  requestStatesReverse: {
    1: 'New',
    2: 'Approved',
    3: 'Rejected',
    4: 'Draft',
    5: 'Confirmed',
  },
  requestSources: {
    new: 1,
    similar: 2,
    carryover: 3,
    similarStyle: 4,
    editArticle: 5,
  },
  requestTypes: {
    AddColorway: { key: 'AddColorway', label: 'modelCreate.title.articleRequest' },
    CarryoverArticle: { key: 'CarryoverArticle', label: 'modelCreate.title.carryoverArticleRequest' },
    SimilarStyle: { key: 'SimilarStyle', label: 'modelCreate.title.similarModelRequest' },
    EditAttribute: { key: 'EditAttribute', label: 'requests.title.editAttributeRequest' },
    EditStatus: { key: 'EditStatus', label: 'requests.title.editStatusRequest' },
    EditArticleState: { key: 'EditArticleState', label: 'requests.title.editArticleStateRequest' },
    EditSizeScale: { key: 'EditSizeScale', label: 'requests.title.editSizeScaleRequest' },
    EditModelName: { key: 'EditModelName', label: 'requests.title.editModelNameRequest' },
    EditPrimaryColor: { key: 'EditPrimaryColor', label: 'requests.title.editPrimaryColorRequest' },
    EditPrices: { key: 'EditPrices', label: 'requests.title.editPricesRequest' },
  },
  requestVersion: 2,
}

export enum editRequestKeys {
  colorId = 'ColorId',
  status = 'Status',
  modelName = 'ModelName',
  sizeScale = 'SizeScale',
  stateName = 'StateName',
  prices = 'Prices',
}

export const appConstants = {
  debounce: {
    input: 300,
  },
  limits: {
    favoriteTags: 50,
    attributeLookupValues: 500,
  },

  staticAttributes: {
    ArticleNumber: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.articleNumber',
      SystemName: 'ArticleNumber',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: true,
      IsSeasonless: true,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ModelId: {
      SystemName: 'ModelId',
      DisplayName: 'general.modelId',
      AttributeType: AttributeType.Int,
      AttributeSource: 'self',
      Visible: true,
      Creatable: false,
      Editable: false,
      IsRequired: false,
      Overridable: false,
      IsSeasonless: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: true,
      IsRequest: false,
      Criteria: {},
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      SortOrder: 0,
      AllowFiltering: false,
      FilterLookup: new Map(),
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ModelNumber: {
      SystemName: 'ModelNumber',
      DisplayName: 'general.modelNumber',
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Visible: true,
      Creatable: true,
      Editable: true,
      IsRequired: true,
      Overridable: false,
      IsSeasonless: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: true,
      IsRequest: false,
      Criteria: {},
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      SortOrder: 0,
      AllowFiltering: true,
      FilterLookup: new Map(),
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ModelName: {
      SystemName: 'ModelName',
      DisplayName: 'general.modelName',
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Visible: true,
      Creatable: true,
      Editable: true,
      IsRequired: true,
      Overridable: false,
      IsSeasonless: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: true,
      IsRequest: false,
      Criteria: {},
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      SortOrder: 0,
      AllowFiltering: true,
      FilterLookup: new Map(),
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    SizeScale: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.sizeScale',
      SystemName: 'SizeScale',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: true,
      IsSeasonless: true,
      IsModelLevel: true,
      IsRequest: false,
      Overridable: false,
      IsStatic: true,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    Status: {
      AttributeType: AttributeType.Bool,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.status',
      SystemName: 'Status',
      AllowFiltering: true,
      FilterLookup: new Map([
        [-1, 'general.all'],
        [1, 'general.active'],
        [0, 'general.inactive'],
      ]),
      IsRequired: true,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    SortOrder: {
      AttributeType: AttributeType.Int,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.sortOrder',
      SystemName: 'SortOrder',
      AllowFiltering: false,
      FilterLookup: new Map(),
      IsRequired: true,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _WholesalePrice: {
      AttributeType: AttributeType.Decimal,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'priceGroupsLabel.wholesalePrice',
      SystemName: '_WholesalePrice',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _RetailPrice: {
      AttributeType: AttributeType.Decimal,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'priceGroupsLabel.retailPrice',
      SystemName: '_RetailPrice',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _OutletPrice: {
      AttributeType: AttributeType.Decimal,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'priceGroupsLabel.outletPrice',
      SystemName: '_OutletPrice',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ShipmentStartDate: {
      AttributeType: AttributeType.Date,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'updateArticleRetailWindow.shipmentStartDate',
      SystemName: 'ShipmentStartDate',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      ReadOnly: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ShipmentEndDate: {
      AttributeType: AttributeType.Date,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'updateArticleRetailWindow.shipmentEndDate',
      SystemName: 'ShipmentEndDate',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      ReadOnly: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    RetailIntroMonth: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: true,
      Editable: false,
      DisplayName: 'updateArticleRetailWindow.retailIntroMonth',
      SystemName: 'RetailIntroMonth',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: true,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: true,
    } as IMyAttribute,
    RetailExitMonth: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: true,
      Editable: false,
      DisplayName: 'updateArticleRetailWindow.retailExitMonth',
      SystemName: 'RetailExitMonth',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: true,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: true,
    } as IMyAttribute,
    Period: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'updateArticleRetailWindow.period',
      SystemName: 'Period',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: true,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    StateName: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'general.articleState',
      SystemName: 'StateName',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
      sortList: false,
    } as IMyAttribute,
    ModelStateName: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'general.modelState',
      SystemName: 'ModelStateName',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: true,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
      sortList: false,
    } as IMyAttribute,
    ArticleLifecycle: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'general.articleLifecycle',
      SystemName: 'ArticleLifecycle',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: true,
    } as IMyAttribute,
    ModelLifecycle: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'general.modelLifecycle',
      SystemName: 'ModelLifecycle',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: true,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: true,
    } as IMyAttribute,
    _DeliveryDates: {
      AttributeType: AttributeType.MultiValue,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.deliveryDates',
      SystemName: '_DeliveryDates',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _Segmentations: {
      AttributeType: AttributeType.MultiValue,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.segmentations',
      SystemName: '_Segmentations',
      AllowFiltering: false,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _FavoriteTags: {
      AttributeType: AttributeType.MultiValue,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.favoriteTags',
      SystemName: '_FavoriteTags',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _Seasons: {
      AttributeType: AttributeType.MultiValue,
      AttributeSource: 'self',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.seasons',
      SystemName: '_Seasons',
      AllowFiltering: false,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: true,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ColorId: {
      AttributeType: AttributeType.ColorPalette,
      AttributeSource: 'static',
      Creatable: true,
      Editable: true,
      DisplayName: 'general.primaryColor',
      SystemName: 'ColorId',
      AllowFiltering: false,
      FilterLookup: new Map(),
      IsRequired: true,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ReadOnly: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: [],
      Visible: false,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ParentModelNumber: {
      SystemName: 'ParentModelNumber',
      DisplayName: 'fields.parentModelNumber',
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Visible: true,
      Creatable: true,
      Editable: false,
      IsRequired: false,
      Overridable: false,
      IsSeasonless: false,
      IsModelLevel: true,
      IsRequest: false,
      IsStatic: false,
      Criteria: {},
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: [],
      SortOrder: 0,
      AllowFiltering: false,
      FilterLookup: new Map(),
      ReadOnly: true,
      ExternalChangeManagementURL: undefined,
      IsPersonal: true,
      prePopulate: true,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ParentModelName: {
      SystemName: 'ParentModelName',
      DisplayName: 'fields.parentModelName',
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Visible: true,
      Creatable: true,
      Editable: false,
      IsRequired: false,
      Overridable: false,
      IsSeasonless: false,
      IsModelLevel: true,
      IsRequest: false,
      IsStatic: false,
      Criteria: {},
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: [],
      SortOrder: 0,
      AllowFiltering: false,
      FilterLookup: new Map(),
      ReadOnly: true,
      ExternalChangeManagementURL: undefined,
      IsPersonal: true,
      prePopulate: true,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    ColorGroup: {
      SystemName: 'ColorGroup',
      DisplayName: 'fields.colorGroup',
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Visible: true,
      Creatable: false,
      Editable: false,
      IsRequired: false,
      Overridable: false,
      IsSeasonless: false,
      IsModelLevel: false,
      IsRequest: false,
      IsStatic: true,
      Criteria: {},
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: [],
      SortOrder: 0,
      AllowFiltering: true,
      FilterLookup: new Map(),
      ReadOnly: true,
      ExternalChangeManagementURL: undefined,
      IsPersonal: false,
      prePopulate: false,
      FilterLookupFromArticle: true,
    } as IMyAttribute,
    ColoredUpBy: {
      SystemName: 'ColoredUpBy',
      DisplayName: 'fields.coloredUpBy',
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'self',
      Visible: true,
      Creatable: false,
      Editable: false,
      IsRequired: false,
      Overridable: false,
      IsSeasonless: false,
      IsModelLevel: false,
      IsRequest: false,
      IsStatic: true,
      Criteria: {},
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: [],
      SortOrder: 0,
      AllowFiltering: true,
      FilterLookup: new Map(),
      ReadOnly: true,
      ExternalChangeManagementURL: undefined,
      IsPersonal: false,
      prePopulate: false,
      FilterLookupFromArticle: true,
    } as IMyAttribute,
    ColoredUpDate: {
      AttributeType: AttributeType.Date,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'fields.coloredUpDate',
      SystemName: 'ColoredUpDate',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    CreatedDate: {
      AttributeType: AttributeType.Date,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'fields.createdDate',
      SystemName: 'CreatedDate',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    UpdatedDate: {
      AttributeType: AttributeType.Date,
      AttributeSource: 'self',
      Creatable: false,
      Editable: false,
      DisplayName: 'fields.updatedDate',
      SystemName: 'UpdatedDate',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _SourceModelNumber: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'request',
      Creatable: true,
      Editable: true,
      DisplayName: 'filter.sourceModelNumber',
      SystemName: '_SourceModelNumber',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
    _RequestSource: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'request',
      Creatable: true,
      Editable: true,
      DisplayName: 'filter.requestArticles',
      SystemName: '_RequestSource',
      AllowFiltering: true,
      FilterLookup: new Map([
        [-1, 'filter.allArticles'],
        [-2, 'filter.catalogArticles'],
        [-3, 'filter.requestArticles'],
        [requestConstants.requestSources.new, 'filter.newArticleRequest'],
        [requestConstants.requestSources.carryover, 'filter.carryoverArticleRequest'],
        [requestConstants.requestSources.similar, 'filter.similarArticleRequest'],
        [requestConstants.requestSources.similarStyle, 'filter.similarModelRequest'],
      ]),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: true,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
      sortList: false,
    } as IMyAttribute,
    _RequestState: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'request',
      Creatable: true,
      Editable: true,
      DisplayName: 'filter.requestStatus',
      SystemName: '_RequestState',
      AllowFiltering: true,
      FilterLookup: new Map([
        [requestConstants.requestStates.new, 'filter.new'],
        [requestConstants.requestStates.approve, 'filter.approve'],
        [requestConstants.requestStates.reject, 'filter.reject'],
        [requestConstants.requestStates.draft, 'filter.draft'],
        [requestConstants.requestStates.confirm, 'filter.confirm'],
      ]),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: true,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
      sortList: false,
    } as IMyAttribute,
    _RequestReason: {
      AttributeType: AttributeType.Nvarchar,
      AttributeSource: 'request',
      Creatable: true,
      Editable: true,
      DisplayName: 'filter.requestReason',
      SystemName: '_Reason',
      AllowFiltering: true,
      FilterLookup: new Map(),
      IsRequired: false,
      IsSeasonless: false,
      Overridable: false,
      IsStatic: true,
      IsPersonal: false,
      IsModelLevel: false,
      IsRequest: false,
      ValidationExpression: '',
      ValidationMessage: '',
      VettingList: undefined,
      Visible: true,
      Criteria: {},
      DefaultValue: undefined,
      ExternalChangeManagementURL: undefined,
      FilterLookupFromArticle: false,
    } as IMyAttribute,
  },

  staticFieldTemplate: {
    AttributeType: AttributeType.Int,
    AttributeSource: 'self',
    Creatable: false,
    Editable: false,
    DisplayName: '',
    SystemName: '',
    AllowFiltering: false,
    FilterLookup: new Map(),
    IsRequired: false,
    IsSeasonless: false,
    Overridable: false,
    IsStatic: true,
    IsPersonal: false,
    IsModelLevel: false,
    IsRequest: false,
    ValidationExpression: '',
    ValidationMessage: '',
    VettingList: [],
    Visible: false,
    Criteria: {},
    DefaultValue: undefined,
    ExternalChangeManagementURL: undefined,
    FilterLookupFromArticle: false,
  } as IMyAttribute,

  staticFormFieldTemplate: {
    AttributeType: AttributeType.Int,
    AttributeSource: 'self',
    Creatable: false,
    Editable: false,
    DisplayName: '',
    SystemName: '',
    IsRequired: false,
    IsSeasonless: false,
    IsModelLevel: true,
    ValidationExpression: '',
    ValidationMessage: '',
    VettingList: undefined,
    Visible: false,
    Criteria: {},
  } as IFormEditorAttribute,

  excludeSegmentationNames: new Set(['all']),
  catalogTypes: {
    master: 1,
    inherited: 3,
  },
  addArticleTypes: {
    newModel: 0,
  },
  threeCharMonths: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
}

export enum ArticlesListToolbarActions {
  // article list toolbar action should be added here
  getBucketsAttributeValue = 0,
  generateTargetsReport = 1,
  generateSummary = 2,
}

export const thumbnailConstants = {
  thumbnailSizeNumberToStrEnum: {
    1: 'small',
    2: 'medium',
    3: 'large',
  },
  thumbnailSizeStrToNumberEnum: {
    small: 1,
    medium: 2,
    large: 3,
  },
}

export const imageConstants = {
  maxImageSize: 5 * 1024 * 1024,
  validImageFormats: new Set(['image/jpeg', 'image/jpg', 'image/png']),
}

export const privileges = {
  article: {
    getList: 'GetCatalogArticleList',
    update: 'UpdateCatalogArticleDetails',
    updateStatus: 'UpdateArticleStatusBulk',
    updateSegmentation: 'UpdateCatalogArticleBulkSegmentation',
    updateRetailWindow: 'UpdateArticleShipmentWindowRange',
    updateSizeScale: 'AssignCatalogModelSizeScale',
    updateForecast: 'CreateUpdateArticleForecastQuantity',
    getForecasts: 'GetArticleForecasts',
    getHistory: 'GetArticleHistory',
    carryOverArticles: 'AssignModelArticlesToCatalog',
    createModelWithArticles: 'CreateModelWithArticles',
    UpdateCatalogModelPrices: 'UpdateCatalogModelPrices',
    UpdateCatalogArticleBulkPrices: 'UpdateCatalogArticleBulkPrices',
    GetNextArticleNumberFromSequence: 'GetNextArticleNumberFromSequence',
    AssignArticleStateToArticles: 'AssignArticleStateToArticles',
    updateParentModel: 'ModifyParentModelInfoBulk',
    importArticles: 'UpdateCatalogBulkArticle',
    overwriteColorCode: 'AssignArticleColors',
    updateSizeScaleBulk: 'BulkAssignCatalogModelSizeScale',
    viewUnAssortedArticles: 'UI_ViewUnAssortedArticles',
    addOrCarryoverArticles: 'AddOrCarryoverArticles',
    addOrCarryoverArticlesRequest: 'AddOrCarryoverArticlesRequest',
    lockArticles: 'LockArticles',
    unlockArticles: 'UnLockArticles',
  },
  favorite: {
    createUpdate: 'CreateUpdateUserFavorites',
    updateStatus: 'UpdateUserFavoritesStatus',
  },
  resource: {
    create: 'CreateResourceWithArticlesOptional',
    assign: 'AssignArticlesToResource',
  },
  objectRelation: {
    get: 'GetObjectRelationDetails',
    create: 'CreateObjectRelations',
    delete: 'DeleteObjectRelation',
  },
  whiteboard: {
    allowExternalImageToArticle: 'AllowExternalImageToArticle',
  },
  stock: {
    getDefinitions: 'GetStockDefinitionList',
    getList: 'GetStockList',
  },
  request: {
    updateStatus: 'UpdateRequestStatuses',
    approveRequest: 'ApproveRequests',
    rejectRequest: 'RejectRequests',
    confirmRequest: 'ConfirmRequest',
    // dummy privileges
    requestsToEditArticlesAttributes: 'RequestsToEditArticlesAttributes',
    requestsToEditPrices: 'RequestsToEditPrices',
    requestsToEditArticlesStatus: 'RequestsToEditArticlesStatus',
    requestsToEditArticlesPrimaryColor: 'RequestsToEditArticlesPrimaryColor',
    requestsToEditArticlesState: 'RequestsToEditArticlesState',
    requestsToEditArticlesSizeScale: 'RequestsToEditArticlesSizeScale',
    requestsToEditModelName: 'RequestsToEditModelName',
  },
  target: {
    getTargetsReport: 'GetTargetsReport',
  },
  admin: {
    nav: {
      articles: ['nav_articles', 'GetCatalogArticleList'],
      articleDetails: 'GetCatalogArticleDetails',
      requests: ['nav_requests'],
    },
    articles: {
      UpdateArticleShipmentWindowRange: 'UpdateArticleShipmentWindowRange',
      listActions: {
        UI_ImportArticles: 'UI_ImportArticles',
        UpdateOnlyCatalogBulkArticle: 'UpdateOnlyCatalogBulkArticle',
        UpdateBulkAttributesByModelNumbers: 'UpdateBulkAttributesByModelNumbers',
        ImportArticlesByLicenseeInfo: 'ImportArticlesByLicenseeInfo',
        UpdateSizeScaleByLicensee: 'UpdateSizeScaleByLicensee',
        UpdatePricesByLicensee: 'UpdatePricesByLicensee',
      },
    },
  },
}

export const savedViewConstants = {
  folderPathSeparator: '|',
}

export const merchConstants = {
  folderPathSeparator: '|',
  folderPathViewSeparator: ' | ',
  slideImageDefaultScaleFactors: {
    large: 0.5,
    medium: 0.35,
    small: 0.20,
  },
  slideArticleThumbnailFontSizes: {
    large: 15,
    medium: 12,
    small: 9,
  },
  slideSize: {
    wideScreen: 'wideScreen',
    A3: 'A3',
    A4: 'A4',
  },
  slideSizeCanvasDimensions: {
    wideScreen: {
      height: 720,
      width: 1280,
    },
    A3: {
      height: 1120,
      width: 1584,
    },
    A4: {
      height: 794,
      width: 1120,
    },
  },
  objectTypes: {
    articleImage: 'articleImage',
    articleDetails: 'articleDetails',
    image: 'image',
    rectangle: 'rectangle',
    circle: 'circle',
    line: 'line',
    triangle: 'triangle',
    group: 'group',
    textBox: 'textbox',
    discussion: 'DiscussionIcon',
  },
  defaultImageType: [
    { key: 'newestImage', value: 'Newest Image' },
    { key: 'firstImage', value: 'First Image' },
  ],
  slideGenTemplatesId: {
    standard: 1,
    uaStandard: 2,
    visualLineBuilder: 3,
    techSheet: 5,
    browseByAttribute: 10,
    developmentReview: 11,
  },
  slideSizePPTDimensions: {
    wideScreen: {
      height: 7.5,
      width: 13.35,
    },
    A3: {
      height: 11.69,
      width: 16.54,
    },
    A4: {
      height: 8.27,
      width: 11.69,
    },
  },
}

export const whiteboardConstants = {
  objectTypes: {
    modelImage: 'WbModelImage',
    articleImage: 'WbArticleImage',
    modelDetails: 'WbModelDetails',
    articleDetails: 'WbArticleDetails',
    connector: 'WbConnector',
    cursor: 'WbCursor',
    image: 'WbImage',
    path: 'WbPath',
    shape: 'WbShape',
    sticky: 'WbSticky',
    textBox: 'WbTextBox',
    discussion: 'WbDiscussionIcon',
    frame: 'WbFrame',
    group: 'group',
    dynamic: 'WbDynamic',
  },
  frameTemplatesId: {
    standard: 1,
    visualLineBuilder: 3,
  },
  frameImageDefaultScaleFactors: {
    small: 20,
    medium: 35,
    large: 50,
  },
  generateSlideImageScaleFactor: {
    small: 12,
    medium: 21,
    large: 30,
  },
  frameArticleThumbnailFontSizes: {
    large: 15,
    medium: 12,
    small: 9,
  },
  debounce: {
    APICall: 250,
  },
  mouseButtons: {
    left: 0,
    middle: 1,
    right: 2,
  },
  recentWhiteboardsContainerName: 'RecentWhiteboards',
}

export const catalogsConstants = {
  recentCatalogsContainerName: 'RecentCatalogs',
}

export const containerTypeConstants = {
  recentWhiteboards: 'RecentWhiteboards',
}

export const ordersConstants = {
  orderProcessStatus: {
    draft: 1,
    submitted: 2,
    disapproved: 3,
    approved: 4,
    reopened: 5,
    locked: 6,
    submitting: 7,
    confirmed: 8,
    approvalTimeout: 10,
  },
  noneEditableProcessStatuses: [2, 3, 4, 6, 7, 8],
}

export const exportConstants = {
  pdfTemplatesId: {
    uaStandard: 1,
  },
}

export const backgroundJobConstants = {
  sourceApplication: 'T1 Studio Web',
  sourceAction: {
    carryoverArticles: 'Carryover Articles',
    copyModel: 'Copy Model',
    assortArticles: 'Assort Articles',
    editSizes: 'Edit Sizes',
    importArticles: 'Import Articles',
  },
}
